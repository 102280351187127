import React, { useState } from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  ConversationHeader,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import '../themes/default/main.scss';
import responses from './responses';
import MessageComponent from './MessageComponent/MessageComponent';
import { useMessages } from '../hooks/useMessages';
import { fetchApiResponse, sendToSpreadsheet } from '../hooks/useApi';

const ChatUI = () => {
  const { messages, addMessage, addBotMessage } = useMessages();
  const [isTyping, setIsTyping] = useState(false);
  // APIとのやり取りが終わったかどうかを追跡する状態
  const [apiInteractionFinished, setApiInteractionFinished] = useState(false);
  const [lastReceivedMessage, setLastReceivedMessage] = useState(null);
  const [lastSentMessage, setLastSentMessage] = useState(null);

  const handleSend = async(value) => {
    setIsTyping(true);
    addMessage(value, "You", "outgoing");
  
    setApiInteractionFinished(false); // APIとのやり取りが始まったことを示す
  
    if (responses[value]) {
      addBotMessage(responses[value]);
    } else {
      await handleApiResponse(value);
    }
    setIsTyping(false);
  };
  
  function extractTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  }
  
  const handleApiResponse = async(value) => {
    // メッセージを送信した後で最後に送信したメッセージを更新
    value = extractTextFromHTML(value);
    setLastSentMessage(value);
    // APIからの応答メッセージを取得
    const content = await fetchApiResponse(value);
    if (content === null) {
      setIsTyping(false);
      return;
    }
    // ボットからの応答メッセージを追加
    addBotMessage(content);
    // メッセージを受信した後で最後に受信したメッセージを更新
    setLastReceivedMessage(content);
    setApiInteractionFinished(true); // APIとのやり取りが終わったことを示す
    setIsTyping(false);
    await sendToSpreadsheet(value, content);
  };

  return (
    <div style={{ position: "relative", height: "auto" }}>
      <MainContainer style={{ width: "100vw", height: "100vh" }}>
        <ChatContainer style={{ width: "100%", height: "100%" }}>
          <ConversationHeader style={{ backgroundColor: "#a28877" }}>
            <ConversationHeader.Content userName="恵比寿ウィメンズクリニック AI問い合わせ"/>
          </ConversationHeader>
          <MessageList>
            {messages.map((msg, index) => (
              <>
                <MessageComponent key={index}  msg={msg} index={index} apiInteractionFinished={apiInteractionFinished} isLastBotMessage={index === messages.reduce((lastIndex, currentMsg, currentIndex) => currentMsg.sender === "Bot" ? currentIndex : lastIndex, -1)} />
              </>
            ))}
            {isTyping && <TypingIndicator style={{ position: 'sticky', bottom: 0 }} content="回答準備中" />}
          </MessageList>
          <MessageInput placeholder="質問を入力してください" onSend={handleSend} attachButton={false}/>
        </ChatContainer>
      </MainContainer>
    </div>
  );
}

export default ChatUI;