// api.js
export const fetchApiResponse = async (value) => {
  const response = await fetch(process.env.REACT_APP_AZURE_URL, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ text: value })
  });

  if (!response.headers.get("content-type")?.includes("application/json")) {
    return null;
  }

  const data = await response.json();
  return data.message[0].content;
};

export const sendToSpreadsheet = async(questionContent, answerContent) => {
  var category = "log";
  if(answerContent === "申し訳ございません。回答が見つかりませんでした。"){
    category = "learn"
  }
  // User-Agentの取得
  const userAgent = window.navigator.userAgent;
  // Refererの取得
  const referer = document.referrer;
  const currentUrl = window.location.href;
  // リクエストされたURLの取得
  const requestedUrl = window.location.href;
  // 質問内容と回答内容をセットでJSON形式に変換し、スプレッドシートに送信する
  const payload = JSON.stringify({ 
    question: questionContent, 
    answer: answerContent, 
    category: category,
    userAgent: userAgent,
    referer: referer,
    requestedUrl: requestedUrl,
    currentUrl: currentUrl,
  });
  // 'Content-Type'を'application/x-www-form-urlencoded'に変更し、'mode'を'no-cors'に設定
  const newheaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };      
  await fetch(process.env.REACT_APP_GAS_URL, {
    method: "POST",
    headers: newheaders,
    body: payload
  });
};